import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';

import { TitleComponent } from '_components/TitleComponent/TitleComponent';

import {
    empty, logIt
} from '__globals/global-functions';

import {CustomContent} from '_pages/Variations/CustomContent/CustomContent';

import {
    getJsonContent, getJsonResultCopy,
    setContent
} from "__globals/copy-functions";

import {executeAction} from "__globals/actions";

import {
    postEntry,
    processErrMsgDisplay,
    doSendValidationEmail
} from "_features/entries/entry-slice";

import {addCSSClass, removeCSSClass} from "../../../__globals/css-functions";
import {getGameName} from "../../../__globals/iw-functions";
import PageFlow from "../../__routes/PageFlow";
import * as GC from "../../../__globals/GLOBAL-CONSTANTS";


export const VerifyEntry = props => {

    logIt( "VerifyEntry IS CALLED", "", false );

    const dispatch = useDispatch();

    let errorMsgDisplay = useSelector(state => state.entry.errorMsgDisplay);
    const setErrorMsgDisplay = (errMsg) => {
        dispatch( processErrMsgDisplay(errMsg) );
    }

    const promotion = useSelector(state => state.promotion);
    // logIt( "PROMOTION at VerifyEntry", promotion, true );

    const entry = useSelector(state => state.entry.entry);
    logIt( "ENTRY AT VerifyEntry", entry, true );

    const validationSent = useSelector(state => state.entry.validationSent);
    logIt( "validationSent AT VerifyEntry", validationSent, true );

    const gameClaim = useSelector(state => state.entry.gameClaim);
    logIt( "gameClaim AT VerifyEntry", gameClaim, true);

    let error = useSelector(state => state.entry.error);
    //logIt( "ERROR AT ENTER", error, true );

    // LOCAL VARIABLES
    const [showTitle, setShowTitle] = useState( false);
    const [showCopy, setShowCopy] = useState( true);
    const [showActionOne, setShowActionOne] = useState( false);
    const [showActionTwo, setShowActionTwo] = useState( false);
    const [showActionThree, setShowActionThree] = useState( false);

    const [actionOne, setActionOne] = useState( '');
    const [valueOne, setValueOne] = useState( '');

    const [actionTwo, setActionTwo] = useState( '');
    const [valueTwo, setValueTwo] = useState( '');

    const [actionThree, setActionThree] = useState( '');
    const [valueThree, setValueThree] = useState( '');

    const [goNext, setGoNext] = useState(false);

    const [ promocracyOn, setPromocracyOn ] = useState(false);

    const [ useTwoStageOn, setUseTwoStageOn ] = useState(true);
    const [ currentStage, setCurrentStage ] = useState(1);
    const [ showValidationForm, setShowValidationForm ] = useState(false);
    const [verifyCode, setVerifyCode] = useState('');
    const onChangeVerifyCode = e => { setVerifyCode(e.target.value) }

    const handleActionButton1 = () => {
        executeAction( actionOne, valueOne );
    }

    const handleActionButton2 = () => {
        executeAction( actionTwo, valueTwo );
    }

    const handleActionButton3 = () => {
        executeAction( actionThree, valueThree );
    }

    {/** FUNCTIONS **/}
    const submitValidationForm = (e) => {
        e.preventDefault();
        if ( !validateEntry() ) {
            return;
        }
        let entryObj = {...entry};
        entryObj.code = verifyCode;

        let gameName = getGameName( promotion );

        dispatch( postEntry(entryObj, null, null, promotion.configuration.name, gameName) );

    }

    const sendValidationEmail = () => {
        let entryObj = {...entry};
        dispatch( doSendValidationEmail(entryObj) );
    }
    {/** END FUNCTIONS **/}

    {/**  VALIDATION */}
    const validateEntry = () => {
        removeCSSClass("verifyCode", "form-error");
        if ( verifyCode === '' || verifyCode === undefined ) {
            addCSSClass("verifyCode", "form-error");
            setErrorMsgDisplay( "Please enter the code from the email." );
            return false;
        }
        return true;
    }
    {/** END VALIDATION **/}

    {/** USE EFFECTS **/}
    /** Sets the page copy from config and any action buttons */
    useEffect(() => {
        if ( !empty(promotion) ) {

            setShowTitle( promotion.configuration?.config?.pages?.verifyEntry?.showTitle );
            setShowCopy( promotion.configuration?.config?.pages?.verifyEntry?.showCopy );
            setShowActionOne( promotion.configuration?.config?.pages?.verifyEntry?.actionOne?.show );
            setShowActionTwo( promotion.configuration?.config?.pages?.verifyEntry?.actionTwo?.show );
            setShowActionThree( promotion.configuration?.config?.pages?.verifyEntry?.actionThree?.show );

            setActionOne( promotion.configuration?.config?.pages?.verifyEntry?.actionOne?.action );
            setValueOne( promotion.configuration?.config?.pages?.verifyEntry?.actionOne?.value );
            setActionTwo( promotion.configuration?.config?.pages?.verifyEntry?.actionTwo?.action );
            setValueTwo( promotion.configuration?.config?.pages?.verifyEntry?.actionTwo?.value );
            setActionThree( promotion.configuration?.config?.pages?.verifyEntry?.actionThree?.action );
            setValueThree( promotion.configuration?.config?.pages?.verifyEntry?.actionThree?.value );

            setPromocracyOn( promotion.configuration.config?.variations?.promocracy?.promocracyOn );

            if ( !validationSent ) {
                sendValidationEmail();
            }

        }
    }, [promotion]);

    useEffect( () => {
        if ( !empty(gameClaim) ) {
            //error = undefined;
            setGoNext( true );
        }
    }, [gameClaim]);

    /** Checks that the validation email has been sent **/
    useEffect( () => {
        if ( !empty(validationSent) && validationSent === true ) {
            setShowValidationForm( true );
        }
    }, [validationSent]);

    useEffect( () => {
        if ( !empty(error) && validationSent ) {
            setErrorMsgDisplay( "Incorrect code. Please enter the code from the email." );
        }
    }, [error]);

    {/** Put in copy based on show flags */}
    useEffect(() => {
        if ( showTitle && !empty(promotion) ) {
            let titleCopy = getJsonContent(promotion, "VerifyEntry", "title", "<h2>DEFAULT TITLE</h2>");
            setContent("title_copy", titleCopy);
        }
    }, [showTitle]);
    useEffect(() => {
        if ( showCopy && !empty(promotion) ) {
            let copyCopy = getJsonContent(promotion, "VerifyEntry", "copy", "<h2>DEFAULT COPY</h2>");
            setContent("copy_copy", copyCopy);
        }
    }, [showCopy]);
    useEffect(() => {
        if ( showActionOne && !empty(promotion) ) {
            let button1Copy = getJsonContent(promotion, "VerifyEntry", "button1", "<h2>DEFAULT BTN 1</h2>");
            setContent("button_1", button1Copy);
        }
    }, [showActionOne]);
    useEffect(() => {
        if ( showActionTwo && !empty(promotion) ) {
            let button2Copy = getJsonContent(promotion, "VerifyEntry", "button2", "<h2>DEFAULT BTN 1</h2>");
            setContent("button_2", button2Copy);
        }
    }, [showActionTwo]);
    useEffect(() => {
        if ( showActionThree && !empty(promotion) ) {
            let button3Copy = getJsonContent(promotion, "VerifyEntry", "button3", "<h2>DEFAULT BTN 1</h2>");
            setContent("button_3", button3Copy);
        }
    }, [showActionThree]);
    {/** END of put in copy based on show flags */}
    {/** END USE EFFECTS **/}

if ( goNext ) {

    return( <PageFlow page={GC.PAGE_ENTER} error={error} /> );

} else {

    return (
        <React.Fragment>
            <TitleComponent title={promotion.configuration.promoCopy.GLOBAL.title} />
            <div id="verify_entry_page">

                <table>
                    <tbody>
                    <tr>
                        <td className="page-visual">
                            <div className="view-desktop"><img src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/verify-entry-screen.jpg`} /></div>
                            <div className="view-phone" style={{display:'none'}}><img src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}phone/verify-entry-screen.jpg`} /></div>
                        </td>
                        <td className="page-content">

                            <div id='verify_entry_content' >
                                {showTitle && <div id="title_copy">Title One</div>}
                                {showCopy && <div id="copy_copy">Copy One</div>}
                                {showActionOne && <div id="button_1" onClick={handleActionButton1}>Action One</div>}
                                {showActionTwo && <div id="button_2" onClick={handleActionButton2}>Action Two</div>}
                                {showActionThree && <div id="button_3" onClick={handleActionButton3}>Action Three</div>}
                            </div>

{/*{ (currentStage === 1) &&*/}

{/*<div>STAGE ONE</div>*/}

{/*} else {*/}

{ (showValidationForm === true) &&
<div id="dv_verify-entry-form">
    <h1>Enter the six-digit validation code we emailed you below:</h1>
    <form>
        <div className="form-filled">
            <div id="verifyCode" className="verifyCode form-error">
                <input name="verifyCode" placeholder="SIX-DIGIT CODE" tabIndex="1" type="text"  onChange={onChangeVerifyCode}/>
            </div>
            <div className="form-submit" onClick={submitValidationForm}>
                <input id="button" name="button" className="view-desktop" src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/btn-form-verify-entry-submit.png`} type="image" />
                <input id="button" name="button" className="view-mobile" src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}phone/btn-form-verify-entry-submit.png`} type="image" />
                { errorMsgDisplay !== undefined ? (
                    <div className="error">{errorMsgDisplay}</div>
                ) : (
                    <div className="error"></div>
                )}
            </div>
        </div>
    </form>
</div>
}

{/* } */}
                            {promotion.variables.promocracyOn && <div id="dv_promocracy-logo">
                                <img
                                    src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/promocracy_logos/${promotion.variables.promocracyLogoImage}${addOn}`}/>
                            </div>}

                        </td>
                    </tr>
                    </tbody>
                </table>



                <CustomContent />

            </div>
        </React.Fragment>

    );

}

}

