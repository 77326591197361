import { empty } from "./global-functions";

export function getMonthDayYearDateFormat( theDate ) {
    if ( !empty(theDate) ) {
        return theDate.toLocaleDateString('en-us', {year: "numeric", month: "long", day: "numeric"});
    }
    return "NO DATE GIVEN"
}

export function checkDateVariations( theDate ) {
    if ( theDate.trim() === "today+1" ) {
        let now = new Date();
        now.setDate(now.getDate() + 1);
        let formattedDate = getMonthDayYearDateFormat( now );
        return formattedDate;
    }
    return false;
}

export function setFormattedTime() {
    let strTimeStamp = "";
    let d = new Date();
    let isoD = d.toISOString();
    return isoD;
}

/** FIND OUT IF THE PROMOTION START DATE IS IN THE PAST */
export function hasPromoStarted( startDateMS ) {
    let startMillis = startDateMS;
    let d = new Date();
    let nowUTC = d.getTime();
    if ( nowUTC < startMillis ) {
        return false;
    } else {
        return true;
    }
}

/** FIND OUT IF THE PROMOTION END DATE IS IN THE PAST */
export function hasPromoEnded( endDateMS ) {
    let endMillis = endDateMS;
    let d = new Date();
    let nowUTC = d.getTime();
    if ( nowUTC > endMillis ) {
        return true;
    } else {
        return false;
    }
}