import React, { useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';

import { TitleComponent } from '_components/TitleComponent/TitleComponent';
import {ActionButtons} from "_components/ActionButtons/ActionButtons";
import {PageCopy} from "_components/PageCopy/PageCopy";
import {PromocracyLogo} from "_components/PromocracyLogo/PromocracyLogo";
import PageFlow from "_pages/__routes/PageFlow";
import {CustomContent} from '_pages/Variations/CustomContent/CustomContent';
import {PageBackgroundImages} from "_components/PageBackgroundImages/PageBackgroundImages";

import { executeAction } from "__globals/actions";
import * as GC from "__globals/GLOBAL-CONSTANTS";
import { logIt } from '__globals/global-functions';


export const AlreadyEntered = props => {

    logIt( GC.PAGE_ALREADY_ENTERED + " PAGE IS CALLED", null, false );

    const dispatch = useDispatch();

    const promotion = useSelector(state => state.promotion);
    //logIt( "PROMOTION at " + GC.PAGE_ALREADY_ENTERED, promotion, true );

    // LOCAL VARIABLES
    const [action, setAction] = useState('');
    const [value, setValue] = useState('');

    const [goNext, setGoNext] = useState(false);

    const doAction = ( a, v ) => {
        if ( a.toUpperCase().trim() === GC.ACTION_NAVIGATE ) {
            setAction(a);
            setValue(v);
            setGoNext(true);
        } else {
            dispatch( executeAction(a, v) );
        }
    }

    if (goNext) {

        return (<PageFlow page={GC.PAGE_ALREADY_ENTERED} error={null} action={action} value={value} />);

    } else {
        return (
            <React.Fragment>
                <TitleComponent title={promotion.configuration.promoCopy.GLOBAL.title}/>
                <div id="already_entered_page">

                    <table>
                        <tbody>
                        <tr>
                            <td className="page-visual">

                                <PageBackgroundImages
                                    promotion={promotion}
                                    pageKey={"already-entered"}
                                />

                            </td>
                            <td className="page-content">

                                <div id='already_entered_content'>

                                    <PageCopy
                                        promotion={promotion}
                                        configKey={"alreadyEntered"}
                                        jsonKey={GC.PAGE_ALREADY_ENTERED}
                                    />

                                    <ActionButtons
                                        promotion={promotion}
                                        configKey={"alreadyEntered"}
                                        jsonKey={GC.PAGE_ALREADY_ENTERED}
                                        doAction={doAction}
                                    />


                                </div>

                                <PromocracyLogo promotion={promotion} />

                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <CustomContent/>

                </div>
            </React.Fragment>

        );

    }

}

