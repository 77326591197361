import { createSlice } from '@reduxjs/toolkit';

import { logIt, empty, getRandomInt, getGameName } from '__globals/global-functions';

import {
	postRemoteEntry,
	getToken,
	getGameClaim
} from '_api/PromotionAPI';

import {
	postValidationEmailEntry
} from '_api/EmailAPI';

import { 	
	postUploadFile
} from '_api/UserContentAPI';
			
import { getConfirmGWPCode } from '_api/GWPAPI';

import { getMonthDayYearDateFormat } from "__globals/date-functions";
import * as GC from '__globals/GLOBAL-CONSTANTS.js';
import {PROMO_TYPE_SIMPLE_SWEEPS} from "../../__globals/GLOBAL-CONSTANTS";


const entrySlice = createSlice({
  name: 'entry',
  initialState:{},
  reducers: {
    processEntry(state, action) {
    	logIt( "processEntry PAYLOAD", action.payload, true );
		state.error = undefined; // clear any lingering error (may want to consider explicit error clear call)
		state.entry = action.payload.entry;
    	state.result = action.payload.result;
		state.token = action.payload.token;
		state.gameClaim = action.payload.gameClaim;

		let newVars = writeVariables( action.payload.variables, action.payload.entry.entry );
		newVars = writeGameClaimVariables( newVars, action.payload.gameClaim );
		logIt( "VARIABLES at processEntry", newVars, true );
		state.variables = newVars;

    	// state.variables.firstName = action.payload.entry.firstName;
    	// state.variables.lastName = action.payload.entry.lastName;
    	// state.variables.emailAddress = action.payload.entry.emailAddress;
    	// state.variables.state = action.payload.entry.state;
    	// state.variables.city = action.payload.entry.city;
    	
    	// TODO: Move this to game claims and have it be per coupon and from the config
    	//state.variables.couponStartsDate = action.payload.entry.couponStartsDate;
    	//state.variables.couponEndsDate = action.payload.entry.couponEndsDate;
//    	if ( !empty(action.payload?.gameClaim?.couponCode) ) {
//    		state.variables.couponCode = action.payload.gameClaim.couponCode;
//    	} else {
//    		state.variables.couponCode = "NO COUPON CODE FOUND";
//    	}
    	
    	// if ( action.payload.promoType === "gift-with-purchase" || action.payload.promoType === "challenge-promo" ) {
    	//
    	// } else {
    	//
    	// }
    	//if ( action.payload.newToken !== undefined && action.payload.newToken !== null && action.payload.newToken !== "" ) {
    	//	state.token = action.payload.newToken;
    	//} else {

    		//state.entryAuthToken = action.payload.token;
    	//}
    		//state.next = action.payload.next;
    		//state.doSuccess = true;
    		// let strState = JSON.stringify( state );
    		// window.localStorage.entry = strState;
    		// state.errorMsgDisplay = '';
    },
	processUpdateEntry( state, action ) {
		state.result = action.payload.result;
		state.entry = action.payload.entry;
		state.gameClaim = action.payload.gameClaim;
		state.token = action.payload.token;
		let oldVars = JSON.parse(JSON.stringify( action.payload.variables ));
		let newVars = writeVariables( oldVars, action.payload.entry.entry );
		//alert(JSON.stringify(newVars))
		if ( !empty(state.gameClaim) ) {
			newVars = writeGameClaimVariables(newVars, action.payload.gameClaim);
		}
		logIt( "VARIABLES at processUpdateEntry", newVars, true );
		state.entryUpdated = true;
		state.variables = newVars;
	},
	processClearEntryUpdated( state, action ) {
		state.entryUpdated = false;
	},
	processWinFormEntry(state, action) {
		logIt( "processWinFormEntry PAYLOAD", action.payload, true );
		state.result = action.payload.result;
		let oldVars = JSON.parse(JSON.stringify( action.payload.variables ));
		let newVars = writeVariables( oldVars, action.payload.entry.entry );
		newVars = writeGameClaimVariables( newVars, action.payload.gameClaim );
		logIt( "VARIABLES at processWinFormEntry", newVars, true );

		state.variables = newVars;
		state.winFormUpdated = true;
	},
	processGameClaimVariables( state, action ) {
		let oldVars = JSON.parse(JSON.stringify( action.payload.variables ));
		let newVars = writeGameClaimVariables( oldVars, action.payload.gameClaim );
		logIt( "VARIABLES at processGameClaimVariables", newVars, true );
		state.variables = newVars;
	},
    processGiftWithPurchaseEntry( state, action ) {
    	logIt( "processGiftWithPurchaseEntry PAYLOAD", action.payload, true );
    	if ( action.payload.gwpConfirmResult.status === "ok" ) {
    		state.result = {};
    		state.result.status = "ok";
    	}
    	state.gwpConfirmResult = action.payload.gwpConfirmResult;
    	state.variables = {};
    	state.variables.firstName = action.payload.entry.firstName;
    	state.variables.lastName = action.payload.entry.lastName;
    	state.variables.emailAddress = action.payload.entry.emailAddress;
    	state.variables.state = action.payload.entry.state;
    	// caching for refresh
		//let strState = JSON.stringify( state );
		//window.localStorage.entry = strState;
		state.errorMsgDisplay = '';
    },
    processNonRegistrationEntry( state, action ) {
    	logIt( "processNonRegistrationEntry PAYLOAD", action.payload, true );
    	state.result = action.payload.result;
    	state.gameClaim = action.payload.gameClaim;
    	state.token = action.payload.token;
    	state.entryAuthToken = action.payload.token;
		//let strState = JSON.stringify( state );
		//window.localStorage.entry = strState;
		state.errorMsgDisplay = '';
    },
    processError(state, action) {
		state.error = action.payload.result;
		state.entry = action.payload.entry;
		//state.doSuccss = false;
		logIt( "processEntryError PAYLOAD", action.payload, true );
    },
	processToken(state, action) {
    	if(action.payload !== undefined)
    	{
    		state.token = action.payload.message;
    		state.tokenRetrieved = true;
    	}
	},
    processErrMsgDisplay(state, action) {
		logIt( "processErrMsgDisplay PAYLOAD", action.payload, false );
		state.errorMsgDisplay = action.payload;
    },
	  processClearErrMsg(state, action) {
		state.errorMsgDisplay = undefined;
	  },

    processTriviaAnswersEntry(state, action) {
    	logIt( "processWinFormEntry PAYLOAD", action.payload, true );
    	state.result = action.payload.result;
    	state.triviaEntriesComplete = true;
    },
    processEmailSendEntry( state, action ) {
    	logIt( "processEmailSendEntry PAYLOAD", action.payload, true );
    	state.result = action.payload.result;
    	state.variables.thankYouItem = action.payload.winnerEntry.entry.thankYouItem;
    },
    processClearStatus(state, action) {
    	logIt( "processClearStatus STATE", state, true );
    	//state.status = "";
    	//state.doSuccess = false;
    	state.error = undefined;
    	state.result = undefined;
    	//state.entry.error = undefined;
    	//state.entry.result = undefined;
    	//state.entry.errorMsgDisplay = undefined;
    },
    processClearNext(state, action) {
    	//state.next = action.payload.next;
    },
    processUploadFile(state, action) {
    	logIt( "processUploadFile PAYLOAD", action.payload, true );
    	state.result = action.payload.fileUploadResult;
    	logIt( "processUploadFile RESULT", state.result, true );
    	state.variables.imagePath = action.payload.fileUploadResult.message;
    	state.variables.caption = action.payload.entry.entry.caption;
    	state.variables.usageRights = action.payload.entry.entry.usageRights;
    },
    processResetEntry( state, action ) {
    	logIt( "action.payload", action.payload, true );
    	state.resetEntry = action.payload;
    	state.error = undefined;
    	state.result = undefined;
    },
    processFatalError( state, action ) {
  	  state.retrieved = true;
  	  state.fatalError = true;
  	  state.message = action.payload.errors[0].message + " - BE SURE TO CHECK THE DATES, CONFIG AND ENTRY GROUP";
    },
	processSendValidationEmail( state, action ) {
		logIt( "RESULT at postValidationEmailEntry", action.payload.result );
		state.validationSent = true;
		state.validationSendResult = action.payload.result;
		state.error = undefined; // clear the previous error
	}
  }
});

export const {
	processVariables,
	processEntry,
	processGameClaimVariables,
	processGiftWithPurchaseEntry,
	processError,
	processFatalError,
	processToken,
	processErrMsgDisplay,
	processClearErrMsg,
	processWinFormEntry,
	processTriviaAnswersEntry,
	processClearStatus,
	processClearNext,
	processUploadFile,
	processResetEntry,
	processEmailSendEntry,
	processNonRegistrationEntry,
	processUpdateEntry,
	processClearEntryUpdated,
	processSendValidationEmail
} = entrySlice.actions;
    
export const fetchToken = ( promoId ) => async (dispatch, getState) => {
	if(getState().entry === undefined || getState().entry.token === undefined)
	{	
		const result = await getToken( promoId );
		if ( result.status === "error" ) {
			dispatch( processFatalError(result) );
		}
		dispatch( processToken(result) );
	}
}

export const postEntry = ( entry, gcToken, gcTokenV2, promoName, gameName, ambassador ) => async (dispatch, getState) => {

	// GET THE PROMO ID
	const testPassword = getState().promotion.configuration.testPassword;
	const promoId = getState().promotion.configuration.id;
	logIt( "promoId", promoId, false );

	// RETRIEVE THE ORIGINAL ENTRY TOKEN
	entry = await retrieveEntryToken( entry, promoId, testPassword );

	// GET THE TWO TYPES OF GOOGLE TOKENS
	entry.googleReCaptchaToken = gcToken;
	entry.googleReCaptchaTokenV2 = gcTokenV2;

	// POST THE ENTRY
	logIt( "ENTRY TO POST", entry, true );
	let originalEntry = entry;
	let result = await postRemoteEntry( entry );

	// CHECK FOR AN ERROR
    if ( result.status === "error" ) {
		let payload = {};
		payload.entry = originalEntry;
		payload.result = result;
    	dispatch( processError(payload) );
    } else {

		// SET THE NEW TOKEN
		let newToken = result.message;

    	// IF WE ARE IN TEST MODE, GET THE TEST RESULT
		let testResult = retrieveTestResult( getState().promotion?.configuration );

		// GET THE GAME CLAIM
		let gameClaim;
		if ( (getState().promotion.configuration.type.value !== GC.PROMO_TYPE_SIMPLE_SWEEPS) &&
			(empty(getState().promotion.configuration?.config?.variations?.pickYourPrize) ||
			(getState().promotion.configuration?.config?.variations?.pickYourPrize.pickYourPrizeOn === false)) )  { // don't do game claim yet if there is a pick your prize page
			gameClaim = await retrieveGameClaim( getState().promotion.configuration.type.value, gameName, newToken, testResult );
		}

		// CHECK FOR A RANDOMIZED THANK YOU CONTENT AND ADD IT TO THE GAME CLAIM
		let thankYouItem = retrieveRandomContent( getState().promotion.configuration );
		if ( !empty(thankYouItem) ) {
			gameClaim.parameters.thankYouItem = thankYouItem;
		}

		// SET VARIABLES
		let variables;
		if ( empty(getState().entry.variables) ) {
			variables = {};
		} else {
			variables = getState().entry.variables;
		}

		// PROCESS THE ENTRY
		let payload = {};
		payload.entry = entry;
		payload.result = result;
		payload.token = result.message;
		if ( !empty(gameClaim) ) {
			payload.gameClaim = gameClaim;
		}
		payload.promotion = getState().promotion;
		payload.variables = variables;
		dispatch( processEntry(payload) );

   }

}

export const updateEntry = ( entryAdd, emailSend ) => async (dispatch, getState) => {

	let updateEntry = {};
	updateEntry.token = getState().entry.token;
	updateEntry.entry = entryAdd.entry;

	// EXTRACT THE PROMO CONFIG
	let promoConfig = getState().promotion.configuration;

	// IF WE ARE IN TEST MODE, GET THE TEST RESULT
	let testResult = retrieveTestResult( promoConfig );

	// GET THE GAME NAME
	let gameName = getGameName( promoConfig );

	// GET THE GAME CLAIM
	let gameClaim;
	if ( promoConfig.type.value !== "simple-sweeps" ) {
		if ( !empty(getState().promotion.configuration?.config?.variations?.pickYourPrize) &&
			(getState().promotion.configuration?.config?.variations?.pickYourPrize.pickYourPrizeOn === true) )  { // don't do game claim yet if there is a pick your prize page
			gameClaim = await retrieveGameClaim(promoConfig.type.value, gameName, updateEntry.token, testResult);
		} else {
			gameClaim = getState().entry.gameClaim;
		}
	}


	// ADD THE EMAIL IF REQUIRED
	if ( emailSend != undefined && emailSend.emailTemplate !== undefined ) {
		updateEntry.emailTemplate = emailSend.emailTemplate;
		updateEntry.emailSubject = emailSend.emailSubject;
	}

	logIt( "UPDATE THE ENTRY", updateEntry, true );
	const result = await postRemoteEntry(updateEntry);

	// SET VARIABLES
	let variables;
	if ( empty(getState().entry.variables) ) {
		variables = {};
	} else {
		variables = JSON.parse(JSON.stringify( getState().entry.variables ));
	}

	// PREP AND PROCESS UPDATE ENTRY
	let payload = {};
	payload.entry = updateEntry;
	payload.result = result;
	payload.token = result.message;
	if ( !empty(gameClaim) ) {
		payload.gameClaim = gameClaim;
	}
	payload.variables = variables;
	dispatch( processUpdateEntry( payload) );

}

export const postWinFormEntry = (winEntry, emailSend) => async (dispatch, getState) => {

	// PREP THE ENTRY
	let preppedEntry = {};
	preppedEntry.token = getState().entry.token;
	preppedEntry.entry = winEntry.entry;

	// EXTRACT THE PROMO CONFIG
	let promoConfig = getState().promotion.configuration;

	// IF WE ARE IN TEST MODE, GET THE TEST RESULT
	let testResult = retrieveTestResult( promoConfig );

	// GET THE GAME NAME
	let gameName = getGameName( promoConfig );

	// GET THE GAME CLAIM
	let gameClaim;
	if ( !empty(getState().promotion.configuration?.config?.variations?.pickYourPrize)) { // if this is a pick your prize, we need a game claim here
		gameClaim = await retrieveGameClaim( promoConfig.type.value, gameName, preppedEntry.token, testResult );
	} else {
		gameClaim = getState().entry.gameClaim;
	}

	// ADD THE EMAIL IF REQUIRED
	if ( emailSend != undefined && emailSend.emailTemplate !== undefined ) {
		preppedEntry.emailTemplate = emailSend.emailTemplate;
		preppedEntry.emailSubject = emailSend.emailSubject;
	}
	logIt( "WINNER ENTRY", preppedEntry, true );

	// POST THE ENTRY
	logIt( "UPDATE THE ENTRY", preppedEntry, true );
	const result = await postRemoteEntry(preppedEntry);

	// SET VARIABLES
	let variables;
	if ( empty(getState().entry.variables) ) {
		variables = {};
	} else {
		variables = JSON.parse(JSON.stringify( getState().entry.variables ));
	}

	// PREP AND PROCESS WINNER ENTRY
	let payload = {};
	payload.entry = preppedEntry;
	payload.result = result;
	payload.token = result.message;
	if ( !empty(gameClaim) ) {
		payload.gameClaim = gameClaim;
	}
	payload.variables = variables;
	dispatch( processWinFormEntry(payload) );

}

export const addGameClaimVariables = (gameClaim) => async (dispatch, getState) => {
	let variables;
	if ( empty(getState().entry.variables) ) {
		variables = {};
	} else {
		variables = JSON.parse(JSON.stringify( getState().entry.variables ));
	}
	let payload = {};
	payload.variables = variables;
	if ( !empty(gameClaim) ) {
		payload.gameClaim = gameClaim;
	}
	dispatch( processGameClaimVariables(payload) );
}

{/**
 HELPER FUNCTIONS THAT CAN BE RE-USED IN VARIOUS SCENARIOS
 **/}
const retrieveEntryToken = async ( psdEntry, promoId, testPassword ) => {
	// GET THE ENTRY TOKEN, CHECK FOR TEST TOKEN
	let entry = psdEntry;
	if ( !empty(testPassword) ) {
		const result = await getToken( promoId, testPassword );
		if ( result.status === "ok") {
			entry.token = result.message;
		}
		//logIt( "TESTING TOKEN", entry.token, false );
	} else {
		const result = await getToken( promoId );
		if ( result.status === "ok") {
			entry.token = result.message;
		}
		//logIt( "NON-TEST TOKEN", entry.token, false );
	}
	return entry;
}

function retrieveTestResult( configuration ) {
	let testResult = null;
	if ('testResult' in configuration) {
		testResult = configuration.testResult;
	}
	return testResult;
}

const retrieveGameClaim = async ( gameType, gameName, newToken, testResult) => {
	let gameClaim;
	if ( gameType === "iw-wheel" || gameType === "iw-match" || gameType === "iw-slot" || "iw-match-configurable" ) {
		gameClaim = await getGameClaim( gameName, newToken, testResult );
	}
	return gameClaim;
}

function retrieveRandomContent( configuration ) {
	let thankYouItem;
	if ( configuration.config.random ) {
		let randomNo = getRandomInt( configuration.config.random.start, configuration.config.random.end );
		thankYouItem = "item"+randomNo;
	}
	return thankYouItem;
}

function writeVariables( variables, entry ) {
	let newVars = variables;
	Object.keys(entry).forEach(function(key) {
		if (
			typeof entry[key] === 'object' &&
			!Array.isArray(entry[key]) &&
			entry[key] !== null
		) {
			// object
		} else {
			newVars[key] = entry[key];
		}
	});
	return newVars;
}

function writeGameClaimVariables( variables, gameClaim ) {
	let newVars = variables;
	if ( !empty(gameClaim) ) { // took this out, what could go wrong -  && !gameClaim.losing
		newVars["couponStartDate"] = getGCParamVal(gameClaim, "couponStartDate");
		let fDate = checkDateVariations(newVars["couponStartDate"]);
		if (checkDateVariations !== false) {
			newVars["couponStartDate"] = fDate;
		}
		newVars["couponEndDate"] = getGCParamVal( gameClaim, "couponEndDate" );
		newVars["prizeWon"] = getGCParamVal( gameClaim, "prizeWon" );
		newVars["verificationCode"] = getGCParamVal( gameClaim, "verificationCode" );
		newVars["couponCode"] = getGCParamVal( gameClaim, "couponCode" );
		newVars["barCodeUrl"] = getBarCodeURL( newVars["couponCode"] );
		newVars["resultName"] = getGCParamVal( gameClaim, "result" );
	}
	return newVars;
}

// TODO: Move this into a "dates" global
function checkDateVariations( theDate ) {
	if ( theDate.trim() === "today+1" ) {
		let now = new Date();
		now.setDate(now.getDate() + 1);
		let formattedDate = getMonthDayYearDateFormat( now );
		return formattedDate;
	}
	return false;
}

function getGCParamVal( gameClaim, param ) {
	//logIt( "Game Claim at getGCParamVal", gameClaim, true);
	if ( !empty(gameClaim) ) {
		if ( !empty(gameClaim.status) ) {
			if ( gameClaim.status === "error" ) {
				return "Invalid Game Claim";
			}
		}
	}
	if ( param === "result" ) {
		return gameClaim[param];
	}
	if ( !empty(gameClaim) && !empty(gameClaim.parameters[param]) ) {
		return gameClaim.parameters[param];
	} else {
		if ( !empty(gameClaim) && !empty(gameClaim[param]) ) {
			return gameClaim[param];
		}
	}
	if ( param === "couponCode" ) {
		return "Placeholder";
	} else {
		return "NOT FOUND: " + param;
	}
}

function getBarCodeURL( couponCode ) {
	if ( !empty(couponCode) ) {
		let bcu = "https://promotion-service.readysetpromo.com/generate-barcode/" + couponCode;
		return bcu;
	} else {
		return "https://promotion-service.readysetpromo.com/generate-barcode/coupon-code-empty";
	}
}




{/**
 END OF HELPER FUNCTIONS THAT CAN BE RE-USED IN VARIOUS SCENARIOS
 **/}


{/**
 BELOW HERE ARE THINGS TO CLEAN UP OR GET RID OF
 **/}

export const resetEntry = () => async (dispatch, getState) => {
	//logIt( "getState().entry", getState().entry, true );
	//logIt( "window.localStorage.entry", window.localStorage.entry, false );
	//let newState = JSON.parse(window.localStorage.entry);
	//logIt( "newState", newState, true );
	let payload = {};
	//payload.reset = true;
	payload = newState;
	dispatch( processResetEntry( payload ) );
}

export const postGiftWithPurchaseEntry = ( entry, gcToken, gcTokenV2, promoName ) => async (dispatch, getState) => {

	entry.token = getState().entry.token;
	if ( empty(entry.token) ) {
		let payload = {}
		payload.message = "No entry token found"
		dispatch( processFatalError(payload) );
	}
	entry.googleReCaptchaToken = gcToken;
	entry.googleReCaptchaTokenV2 = gcTokenV2;
	//logIt("original token", entry.token, false);
	const result = await postRemoteEntry(entry);
	if ( result.status === "error" ) {
		dispatch( processError( result ) );
	} else {
		const newEntryToken = result.message; // updated by the act of posting the entry
		entry.token = newEntryToken;
		//logIt("new token after entry", entry.token, false);
		let code = getState().gwp.validation.code;
		const gwpConfirmResult = await getConfirmGWPCode( code, entry.token );
		if ( gwpConfirmResult.status === "error" ) {
			dispatch( processError( gwpConfirmResult ) );
		}
		checkVariations( entry, getState() );
		let payload = {};
		entry.result = result;
		payload.gwpConfirmResult = gwpConfirmResult;
		payload.promoType = getState().promotion.configuration.type.value;
		payload.entry = entry;
		dispatch( processGiftWithPurchaseEntry( payload ) );
	}
}

const checkVariations = ( entry, currentState ) => {

	// RANDOM VARIATION
	let thankYouItem;
	if ( currentState.promotion.configuration.config.random ) {
		let randomNo = getRandomInt( currentState.promotion.configuration.config.random.start, currentState.promotion.configuration.config.random.end );
		thankYouItem = "item"+randomNo;
		entry.gameClaim.parameters.thankYouItem = thankYouItem;
	}
	// TRADE SHOW VARIATION
	// TODO: Move this here

}


export const postNonRegistrationEntry = ( entry, gameName ) => async (dispatch, getState) => {

	// GET THE PROMO ID
	const testPassword = getState().promotion.configuration.testPassword;
	const promoId = getState().promotion.configuration.id;
	//logIt( "promoId at postNonRegistrationEntry", promoId, false );
	
	// GET THE TOKEN
	let newToken;
	const tokenResult = await getToken( promoId, testPassword );
	if ( tokenResult.status === "ok") {
		newToken = tokenResult.message;
	}
	//logIt( "NEW TOKEN in postNonRegistrationEntry", newToken, false );
	entry.token = newToken;
	
	// POST THE ENTRY
	let result;
	logIt( "ENTRY in postNonRegistrationEntry", entry, true );
	result = await postRemoteEntry(entry);

	// CHECK FOR AN ERROR
    if ( result.status === "error" ) {

    	dispatch(processError(result));
    	
    } else {

    	// IF WE ARE IN TEST MODE, GET THE TEST RESULT
    	let testResult = null;
    	if ('testResult' in getState().promotion.configuration) {
    		testResult = getState().promotion.configuration.testResult;
    		//logIt( "TEST RESULT in postEntry", testResult, true );
    	}
    	
		// GET THE GAME CLAIM
		let gameClaim = "";
		if (	getState().promotion.configuration.type.value === "iw-wheel" ||
				getState().promotion.configuration.type.value === "iw-match" ||
				getState().promotion.configuration.type.value === "iw-match-configurable" ||
				getState().promotion.configuration.type.value === "iw-slot" ) {
				
				if ( !empty(gameName) ) {
					//alert("The passed in Test Result: " + testResult)
					gameClaim = await getGameClaim( gameName, result.message, testResult );
					logIt( "GAME CLAIM at postNonRegistrationEntry", gameClaim, true );
					//alert("The retrieved Test Result: " + gameClaim.result );
				} else {
					//alert("GAME NAME: " + gameName)
					//logIt( "ERROR: GAME NAME EXPECTED BUT NOT FOUND, NO GAME CLAIM RETRIEVED", gameName, true );
					let payload = {};
					payload.msg = "ERROR: GAME NAME EXPECTED BUT NOT FOUND, NO GAME CLAIM RETRIEVED";
					payload.gameName = gameName;
					payload.status = "Error";
					dispatch(processError(payload));
				}
		}

    	// PROCESS THE ENTRY
    	let payload = {};
    	payload = entry;
    	payload.result = result;
    	payload.token = result.message;
    	payload.gameClaim = gameClaim;
    	dispatch( processNonRegistrationEntry(payload) );

   }
     
}





export const postTriviaAnswersEntry = (triviaAnswers) => async (dispatch, getState) => {
	let preppedEntry = {};
	preppedEntry.entry = {};
	//logIt( "NON PREPPED TRIVIA ANSWERS", triviaAnswers, true );
	//logIt( "EMAIL SEND in postWinFormEntry", emailSend, true );
	let pieces;
	triviaAnswers.forEach(function(triviaAnswer, index) {
		pieces = triviaAnswer.split("-");
		let questionKey = pieces[0];
		let answerKey = pieces[1];
		preppedEntry.entry[questionKey] = answerKey;
	});
	preppedEntry.token = getState().entry.token;
	//logIt( "PREPPED TRIVIA ENTRY", preppedEntry, true );
	const result = await postRemoteEntry(preppedEntry);
	//logIt( "WINNER ENTRY RESULT", result, true );
	let payload = {};
	payload.result = result;
	payload.token = result.message;
	payload.winnerEntry = preppedEntry;
	dispatch(processTriviaAnswersEntry(payload));
}

export const postEmailSendEntry = (winEntry, emailSend) => async (dispatch, getState) => {
	let preppedEntry = {};
	preppedEntry.entry = {};
	//logIt( "NON PREPPED WINNER ENTRY", winEntry, true );
	//logIt( "EMAIL SEND in postWinFormEntry", emailSend, true );
	preppedEntry.entry.thankYouItem = winEntry.entry.thankYouItem;
	preppedEntry.token = getState().entry.token;
	if ( emailSend != undefined && emailSend.emailTemplate !== undefined ) {
		preppedEntry.emailTemplate = emailSend.emailTemplate;
		preppedEntry.emailSubject = emailSend.emailSubject;
	}
	//logIt( "PREPPED WINNER ENTRY", preppedEntry, true );
	const result = await postRemoteEntry(preppedEntry);
	//logIt( "WINNER ENTRY RESULT", result, true );
	let payload = {};
	payload.result = result;
	payload.token = result.message;
	payload.winnerEntry = preppedEntry;
	dispatch(processEmailSendEntry(payload));
}

export const clearNext = () => async (dispatch, getState) => {
	let payload = {};
	//payload.next = undefined;
	dispatch( processClearNext(payload) );
}

export const uploadFile = (formData, entryObj) => async (dispatch, getState) => {
	let token = getState().entry.token;
	formData.append("token", token);
	const fileUploadResult = await postUploadFile( formData, token );
	entryObj.token = token;
	const entryResult = await postRemoteEntry( entryObj );
	let payload = {};
	payload.fileUploadResult = fileUploadResult;
	payload.entryResult = entryResult;
	payload.entry = entryObj;
    if ( fileUploadResult.status === "error" ) {
    	dispatch( processError( fileUploadResult ) );
    } else {
    	dispatch( processUploadFile( payload ) );
    }
}

export const setVariables = (entryJSON) => async (dispatch, getState) => {
	let payload = {};
	//alert( JSON.stringify(entryJSON) )
	payload.entry = entryJSON;
	dispatch( processVariables(payload) )
}

export const doSendValidationEmail = (entry) => async (dispatch, getState) => {
	let result = await postValidationEmailEntry( entry );
	let payload = {};
	payload.result = result;
	dispatch( processSendValidationEmail(payload) )
}


export default entrySlice.reducer;


