import sanitizeHtml from "sanitize-html";
import {empty, replaceVars} from "./global-functions";

import * as GC from '__globals/GLOBAL-CONSTANTS.js';

export function setContent( divName, content ) {

    if ( document.getElementById( divName ) ) {
        document.getElementById( divName ).innerHTML = content;
    }
}
export function getJsonContent( promotion, section, name, defaultContent, entry ) {
    let copy = promotion.configuration.promoCopy;
    //alert( JSON.stringify(copy) );
    if ( !empty(copy) ) {
        if ( !empty(section) && !empty(name) ) {
            //alert(JSON.stringify(copy[section]));
            if ( !empty(copy[section]) ) {
                if ( !empty(copy[section][name]) ) {
                    let content = copy[section][name].content;
                    //alert(JSON.stringify(copy[section][name].content));
                    if ( !empty(content) ) {
                        let contentReplaced = replaceVars( content, promotion, entry );
                        //alert(contentReplaced)
                        let cleanedContent = sanitizeHtml( contentReplaced, {
                            allowedTags: sanitizeHtml.defaults.allowedTags.concat ( GC.ALLOWED_TAGS )
                        });
                        return cleanedContent;
                    }
                }
            }
        }
    }
    return defaultContent;
}

export function getJsonSlideContent( promotion, section, name, slideName, defaultContent, entry ) {
    let copy = promotion.configuration.promoCopy;
    //alert( JSON.stringify(copy) );
    if ( !empty(copy) ) {
        if ( !empty(section) && !empty(name) ) {
            //alert(JSON.stringify(copy[section]));
            if ( !empty(copy[section]) ) {
                if ( !empty(copy[section][name]) ) {
                    let content = copy[section][name].content[slideName].content;
                    //alert(JSON.stringify(copy[section][name].content));
                    if ( !empty(content) ) {
                        let contentReplaced = replaceVars( content, promotion, entry );
                        //alert(contentReplaced)
                        let cleanedContent = sanitizeHtml( contentReplaced, {
                            allowedTags: sanitizeHtml.defaults.allowedTags.concat ( GC.ALLOWED_TAGS )
                        });
                        return cleanedContent;
                    }
                }
            }
        }
    }
    return defaultContent;
}

export function getJsonResultCopy( promotion, section, gameClaim, defaultContent, entry ) {
    let copy = promotion.configuration.promoCopy;

    if ( !empty(gameClaim) ) {
        let gcName = gameClaim.result;
        if ( !empty(copy[section]) ) {
            if (!empty(copy[section]["resultCopy"])) {
                let content = copy[section]["resultCopy"][gcName];
                if ( !empty(content) ) {
                    let contentReplaced = replaceVars( content, promotion, entry );
                    //alert(contentReplaced)
                    let cleanedContent = sanitizeHtml( contentReplaced, {
                        allowedTags: sanitizeHtml.defaults.allowedTags.concat (GC.ALLOWED_TAGS )
                    });
                    return cleanedContent;
                }
            }
        }
    }
    return "RESULT COPY ERROR";
}