import React, { useState, useEffect } from 'react';
import {
    empty
} from '__globals/global-functions';
import {
    getJsonContent,
    setContent
} from "__globals/copy-functions";

export const PageCopy = props => {

    // PROMOTION VARIABLES


    // LOCAL VARIABLES
    const [currentPage, setCurrentPage] = useState(undefined);

    const [showTitle, setShowTitle] = useState(false);
    const [showCopy, setShowCopy] = useState(true);

    /** SET THE CURRENT PAGE **/
    useEffect(() => {
        if (!empty(props.promotion)) {
            if ( !empty(props.promotion.configuration?.config?.pages[props.configKey]) ) {
                setCurrentPage( props.promotion.configuration?.config?.pages[props.configKey] );
            }
        }
    }, [props.promotion]);

    /** GET CONTENT ACTIONS AND VALUE FOR THE BUTTONS **/
    useEffect(() => {
        if (!empty(currentPage)) {
            setShowTitle( currentPage.showTitle );
            setShowCopy( currentPage.showCopy );
        }
    }, [currentPage]);
    useEffect(() => {
        if (showTitle && !empty(props.promotion)) {
            let titleCopy = getJsonContent(props.promotion, props.jsonKey, "title", "<h2>DEFAULT TITLE</h2>", props.entry );
            setContent("title_copy", titleCopy);
        }
    }, [showTitle]);
    useEffect(() => {
        if (showCopy && !empty(props.promotion)) {
            let copyCopy = getJsonContent(props.promotion, props.jsonKey, "copy", "<h2>DEFAULT COPY</h2>", props.entry );
            setContent("copy_copy", copyCopy);
        }
    }, [showCopy]);
    /** END GET CONTENT ACTIONS AND VALUE FOR THE BUTTONS **/


    return (
        <React.Fragment>
            {showTitle && <div id="title_copy">Title One</div>}
            {showCopy && <div id="copy_copy">Copy One</div>}
        </React.Fragment>
    );
}