import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { TitleComponent } from '_components/TitleComponent/TitleComponent';

import {CustomContent} from '_pages/Variations/CustomContent/CustomContent';

import ThankYouDefault from "./Results/ThankYouDefault";
import ThankYouECoupon from "./Results/ThankYouECoupon";
import ThankYouECard from "./Results/ThankYouECard";
import ThankYouRedeemForm from "./Results/ThankYouRedeemForm";
import ThankYouLoseOffer from "./Results/ThankYouLoseOffer";

import {
    empty, getRandomUrlAppendage,
    logIt,
} from "__globals/global-functions";

import { executeAction } from "__globals/actions";

import * as GC from '__globals/GLOBAL-CONSTANTS.js';

var addOn = getRandomUrlAppendage("rd");


export const ThankYou = props => {

    logIt( "THANK YOU IS CALLED", "", false );

    const dispatch = useDispatch();

    const state = useSelector( state => state );
    //logIt( "STATE ON THANK YOU", state, true );

    const promotion = useSelector(state => state.promotion);
    //logIt( "PROMOTION", promotion, true );

    let entry = useSelector(state => state.entry);
    logIt( "ENTRY at ThankYou", entry, true);

    let gameClaim = useSelector(state => state.entry.gameClaim);
    logIt( "GAME CLAIM at ThankYou", gameClaim, true);

    let variables = useSelector(state => state.entry.variables);
    logIt( "VARIABLES at ThankYou", variables, true);

    let entryUpdated = useSelector(state => state.entry.entryUpdated);
    logIt( "entryUpdated at ThankYou", entryUpdated, true);

    {/** LOCAL VARIABLES */}
    const [promoType, setPromoType] = useState( promotion.configuration?.type?.value );
    const [thankYouResult, setThankYouResult] = useState( "ThankYou_Default" );


    const handleAction = ( action, value ) => {
        dispatch( executeAction(action, value) );
    }

    useEffect(() => {
        if ( !empty(promoType) && !empty(promotion) ) {
            if ( promoType === GC.PROMO_TYPE_SIMPLE_SWEEPS ) {
                let r = promotion.configuration?.config?.pages?.thankYouScreen?.thankYouResult;
                setThankYouResult( r );
            } else if ( promoType === GC.PROMO_TYPE_IW_MATCH ) {
                let r = gameClaim.parameters?.thankYouScreen?.thankYouResult;
                setThankYouResult( r );
            } else if ( promoType === GC.PROMO_TYPE_IW_MATCH_CONFIGURABLE ) {
                let r = gameClaim.parameters?.thankYouScreen?.thankYouResult;
                setThankYouResult( r );
            } else if ( promoType === GC.PROMO_TYPE_IW_WHEEL ) {
                let r = gameClaim.parameters?.thankYouScreen?.thankYouResult;
                setThankYouResult( r );
            } else if ( promoType === GC.PROMO_TYPE_IW_SLOT ) {
                let r = gameClaim.parameters?.thankYouScreen?.thankYouResult;
                setThankYouResult( r );
            } else if ( promoType === GC.PROMO_TYPE_IW_NO_CLICK_REVEAL ) {
                let r = gameClaim.parameters?.thankYouScreen?.thankYouResult;
                setThankYouResult( r );
            }
        }
    }, [promoType]);



    return (

        <React.Fragment>
            <TitleComponent title={promotion.configuration.promoCopy.GLOBAL.title} />
            <div id="thank_you_page">
                <table>
                    <tbody>
                    <tr>
                        <td className="page-visual">
                            <div className="view-desktop"><img src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/thank-you-screen.jpg`} /></div>
                            <div className="view-phone" style={{display:'none'}}><img src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}phone/thank-you-screen.jpg`} /></div>
                        </td>
                        <td className="page-content">

{/** Thank You Default */}
{(thankYouResult === GC.TY_DEFAULT) && <ThankYouDefault promotion={promotion}  gameClaim={gameClaim} handleAction={handleAction}  entry={entry} />}

{/** Thank You Redeem Form */}
{(thankYouResult === GC.TY_REDEEM) && <ThankYouRedeemForm promotion={promotion} gameClaim={gameClaim} handleAction={handleAction} entry={entry} entryUpdated={entryUpdated} />}

{/** Thank You E-Coupon */}
{(thankYouResult === GC.TY_ECOUPON) && <ThankYouECoupon promotion={promotion} gameClaim={gameClaim} handleAction={handleAction} entry={entry} entryUpdated={entryUpdated} />}

{/** Thank You E-Card */}
{(thankYouResult === GC.TY_ECARD) && <ThankYouECard promotion={promotion} gameClaim={gameClaim} handleAction={handleAction} entry={entry} entryUpdated={entryUpdated} />}

{/** Thank You E-Card */}
{(thankYouResult === GC.TY_LOSE_OFFER) && <ThankYouLoseOffer promotion={promotion} gameClaim={gameClaim} handleAction={handleAction} entry={entry} entryUpdated={entryUpdated} />}

{/** Print logo for PRINT action */}
<div id='print_logo' style={{display:"none"}}>
    <img src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/all/logo-print.jpg`} />
</div>

{promotion.variables.promocracyOn && <div id="dv_promocracy-logo">
    <img
        src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/promocracy_logos/${promotion.variables.promocracyLogoImage}${addOn}`}/>
</div>}

                        </td>
                    </tr>
                    </tbody>
                </table>

                <CustomContent />

            </div>
        </React.Fragment>

    );
}