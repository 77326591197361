import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { TitleComponent } from '_components/TitleComponent/TitleComponent';

import {
	empty, logIt
} from '__globals/global-functions';

import {CustomContent} from '_pages/Variations/CustomContent/CustomContent';

import {
	getJsonContent, getJsonResultCopy,
	setContent
} from "__globals/copy-functions";

import {executeAction} from "__globals/actions";


export const Splash = props => {

	const promotion = useSelector(state => state.promotion);
	//logIt( "PROMOTION at Splash", promotion, true );

	// LOCAL VARIABLES
	const [showTitle, setShowTitle] = useState( false);
	const [showCopy, setShowCopy] = useState( true);
	const [showActionOne, setShowActionOne] = useState( false);
	const [showActionTwo, setShowActionTwo] = useState( false);
	const [showActionThree, setShowActionThree] = useState( false);

	const [actionOne, setActionOne] = useState( '');
	const [valueOne, setValueOne] = useState( '');

	const [actionTwo, setActionTwo] = useState( '');
	const [valueTwo, setValueTwo] = useState( '');

	const [actionThree, setActionThree] = useState( '');
	const [valueThree, setValueThree] = useState( '');

	const handleActionButton1 = () => {
		executeAction( actionOne, valueOne );
	}

	const handleActionButton2 = () => {
		executeAction( actionTwo, valueTwo );
	}

	const handleActionButton3 = () => {
		executeAction( actionThree, valueThree );
	}

	/** Sets the page copy from config and any action buttons */
	useEffect(() => {
		if ( !empty(promotion) ) {
			setShowTitle( promotion.configuration?.config?.pages?.splash?.showTitle );
			setShowCopy( promotion.configuration?.config?.pages?.splash?.showCopy );
			setShowActionOne( promotion.configuration?.config?.pages?.splash?.actionOne?.show );
			setShowActionTwo( promotion.configuration?.config?.pages?.splash?.actionTwo?.show );
			setShowActionThree( promotion.configuration?.config?.pages?.splash?.actionThree?.show );

			setActionOne( promotion.configuration?.config?.pages?.splash?.actionOne?.action );
			setValueOne( promotion.configuration?.config?.pages?.splash?.actionOne?.value );
			setActionTwo( promotion.configuration?.config?.pages?.splash?.actionTwo?.action );
			setValueTwo( promotion.configuration?.config?.pages?.splash?.actionTwo?.value );
			setActionThree( promotion.configuration?.config?.pages?.splash?.actionThree?.action );
			setValueThree( promotion.configuration?.config?.pages?.splash?.actionThree?.value );
		}
	}, [promotion]);

	{/** Put in copy based on show flags */}
	useEffect(() => {
		if ( showTitle && !empty(promotion) ) {
			let titleCopy = getJsonContent(promotion, "Splash", "title", "<h2>DEFAULT TITLE</h2>");
			setContent("title_copy", titleCopy);
		}
	}, [showTitle]);
	useEffect(() => {
		if ( showCopy && !empty(promotion) ) {
			let copyCopy = getJsonContent(promotion, "Splash", "copy", "<h2>DEFAULT COPY</h2>");
			setContent("copy_copy", copyCopy);
		}
	}, [showCopy]);
	useEffect(() => {
		if ( showActionOne && !empty(promotion) ) {
			let button1Copy = getJsonContent(promotion, "Splash", "button1", "<h2>DEFAULT BTN 1</h2>");
			setContent("button_1", button1Copy);
		}
	}, [showActionOne]);
	useEffect(() => {
		if ( showActionTwo && !empty(promotion) ) {
			let button2Copy = getJsonContent(promotion, "Splash", "button2", "<h2>DEFAULT BTN 1</h2>");
			setContent("button_2", button2Copy);
		}
	}, [showActionTwo]);
	useEffect(() => {
		if ( showActionThree && !empty(promotion) ) {
			let button3Copy = getJsonContent(promotion, "Splash", "button3", "<h2>DEFAULT BTN 1</h2>");
			setContent("button_3", button3Copy);
		}
	}, [showActionThree]);
	{/** END of put in copy based on show flags */}

	return (
		<React.Fragment>
			<TitleComponent title={promotion.configuration.promoCopy.GLOBAL.title} />
			<div id="splash_page">

				<table>
					<tbody>
					<tr>
						<td className="page-visual">
							<div className="view-desktop"><img src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/splash-screen.jpg`} /></div>
							<div className="view-phone" style={{display:'none'}}><img src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}phone/splash-screen.jpg`} /></div>
						</td>
						<td className="page-content">

<div id='splash_content' >
	{showTitle && <div id="title_copy">Title One</div>}
	{showCopy && <div id="copy_copy">Copy One</div>}
	{showActionOne && <div id="button_1" onClick={handleActionButton1}>Action One</div>}
	{showActionTwo && <div id="button_2" onClick={handleActionButton2}>Action Two</div>}
	{showActionThree && <div id="button_3" onClick={handleActionButton3}>Action Three</div>}
</div>

						</td>
					</tr>
					</tbody>
				</table>



				<CustomContent />

			</div>
		</React.Fragment>

	);

}

